import React, {useState, useEffect} from 'react';
// import { Link } from 'react-router-dom';
import { Link, Element } from 'react-scroll';
import { Typewriter } from 'react-simple-typewriter'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import image from '../assets/f675838af7bf2c91-sticker.png'
import ProjectCard from '../components/ProjectCard'
// import { Link, animateScroll as scroll } from 'react-scroll';


const words = [
    'UX Researcher',
    'UI Developer',
    'Product Designer',
    'UX Designer',
  ];
  
  const SlidingText = () => {
    return (
      <div className="slider">
        {words.map((word, index) => (
          <div key={index} className={`slider-text-${index+1}`} style={{ color: index % 2 !== 0 ? 'black' : 'white' }}>
            {word}
          </div>
        ))}
      </div>
    );
  };

const BouncingArrow = () => (
    <div className="arrow-container">
        <Link to="projects" smooth={true} duration={500}>
            <FaChevronDown className="bounce" style={{fontSize:"45px", color:"gray"}}/>
        </Link>
    </div>
  );

const Portfolio = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const text = <Typewriter
                    words={[
                        'UX / UI Designer',
                        'Product Designer',
                        'Dev-UX Hybrid Designer',
                        'UX Tester',
                        'UX Researcher'
                    ]}
                    loop={10}
                    cursor
                    cursorStyle='|'
                    typeSpeed={80}
                    deleteSpeed={70}
                    delaySpeed={1000}
                />
    
    const projects = [
        {
            name: "Skill Swap: Bridging Learning and Earning",
            description: "Skill Swap connects learners with experts, offering a platform to gain new skills without the upfront cost of professional training, while allowing experts to earn extra income by sharing their knowledge.",
            prototypeLink:"https://www.figma.com/proto/s7IpsnxbAczuVjZP5VJ8td/UXD5310-Capstone-Project-4?node-id=377-323&t=V3NFw83ShUY9fH70-0&scaling=scale-down&content-scaling=fixed&page-id=318%3A759&starting-point-node-id=377%3A323",
            caseStudyLink:"https://www.behance.net/gallery/205389405/SkillSwap-Case-Study",
            projectType: ["UI Design", "Usability Testing", "User Flow", "Information Architecture"],
            imageSrc: require("../assets/skillswap.jpg"),
            reverse: false
        },
        {
            name: "VRBO Trip Board Reimagined",
            description: "Improving the group-booking experience through research and design",
            prototypeLink:"",
            caseStudyLink:"https://www.behance.net/gallery/193101087/VRBO-Trip-Board-Reimagined",
            projectType: ["User Research", "Usability Testing", "Interface Design"],
            imageSrc: require("../assets/vrbo.jpg"),
            reverse: true
        },
        {
            name: "Clever Cooks",
            description: "Your kitchen ally! Craft delicious recipes effortlessly, utilizing ingredients from your fridge. Minimize waste, maximize savings. A sustainable and cost-effective way to elevate your culinary experience.",
            prototypeLink:"http://clevercooks.duckdns.org/",
            caseStudyLink:"https://www.behance.net/gallery/193386579/Clever-Cooks-Case-Study",
            projectType: ["Information Architecture", "Usability Testing", "Web Development", "UI Design"],
            imageSrc: require("../assets/clevercooks.png"),
            reverse: false
        },
        {
            name: "ReMed: Your Friendly Medication Sidekick",
            description: "Meet ReMed, your friendly reminder buddy! Perfect for older adults and busy adults alike, ReMed helps you stay on top of your meds, appointments, and even your medicine stock. Say goodbye to missed doses and hello to healthy, independent living!.",
            prototypeLink:"https://www.figma.com/proto/q3ssUwDkXzKB33ZHx70CeU/ReMed?node-id=78-4430&t=Wb8zUDk8dfgbAlFQ-1&scaling=scale-down&content-scaling=fixed&page-id=73%3A4162&starting-point-node-id=78%3A4430&show-proto-sidebar=1",
            caseStudyLink:"https://www.behance.net/gallery/169480545/ReMed-Medication-Reminder-App",
            projectType: ["User Research", "Usability Testing", "UI Design", "Interface Design"],
            imageSrc: require("../assets/remed.png"),
            reverse: true
        },
        {
            name: "FurKids: Your Paw-some Adoption Adventure!",
            description: "FurKids makes pet adoption a breeze with easy navigation and all the info you need. Find your furry friend and schedule a meet-up without the hassle!",
            prototypeLink:"https://www.figma.com/proto/aNV1aAeL0owwpvmmv2nNFU/Day-003?node-id=2-2&t=0VNqIh5YiWKSKS3y-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=2%3A2",
            caseStudyLink:"https://www.behance.net/gallery/169556511/FurKids-Pet-Adoption-Website",
            projectType: ["User Interview", "Information Architecture", "Rapid Prototype", "Wireframing"],
            imageSrc: require("../assets/furkids.png"),
            reverse: false
        },
        {
            name: "Optimising Online Shopping",
            description: "Research plan to enhance Roots Canada's online sales by finding the reasons behind a 17% drop in purchases using various research methods.",
            prototypeLink:"",
            caseStudyLink:"https://www.behance.net/gallery/192025437/Roots-Research-Proposal-for-Optimisations",
            projectType: ["Stakeholder Interview", "Usability Testing", "A/B Testing", "Research Plan"],
            imageSrc: require("../assets/Rootspng.png"),
            reverse: true
        },
        {
            name: "Redesign - Pennyjuice Website",
            description: "Coming Soon...",
            prototypeLink:"",
            caseStudyLink:"",
            projectType: ["UI Design", "Research", "Infromation Architecture", "Rapid Prototyping"],
            imageSrc: require("../assets/pennyjuicecomingsoon.png"),
            reverse: false
        },
        
    ];
    return (
        <Container fluid className="mx-0 px-0">
            {/* backgroundColor:"#f5f0db", */}
            <div className="px-3" style={{ minHeight:"60vh"}}>
            <Row className="align-items-center justify-content-center" style={{ minHeight: "50vh"}}>
                {/* Left Column */}
            <Col md={7} className="text-center text-md-center mb-4">
                <h1 className="display-4 mb-4" style={{fontWeight: "900", marginBlockStart:'2.5em'}}>I Elevate User Experiences Through Collaborative Innovation</h1>
                {/* <div className="slider-wrapper mb-4 align-items-center justify-items-center">
                    <SlidingText/>
                </div> */}
                <p className="lead" style={{ color: 'black', fontWeight: "500", lineHeight: "1.6em", textAlign: "center", paddingTop:"1em", paddingBottom:"1em"}}>
                    Hi! I'm Voleta Noronha, passionate about solving problems through creativity, collaboration, and a user-centered approach. With both design and development experience, I bring effective solutions that drive team success. Inspired by Hermione Granger’s relentless pursuit of knowledge, I’m always eager to explore and advance in tech.
                </p>
                <p className="my-2" style={{ color: 'gray', fontWeight: "500", lineHeight: "1.6em", textAlign: "center" }}>SCROLL TO SEE MY LATEST WORK</p>
            </Col >
            

                {/* Right Column */}
                {/* <Col md={3} className="text-center">
                    <img src={image} alt="Voleta's Image" className="img-fluid"/>
                </Col> */}
            </Row>
            
            <BouncingArrow />
            </div>           
            {/*projects */}
            <Element name="projects" className="element">
            <Row id="projects" className="mx-0 align-items-center justify-content-center  py-5 d-flex flex-column justify-content-left">
                {projects.map((project, index) => (
                    <Col key={index} className='my-5'>
                        <ProjectCard {...project} className='my-5'/>
                    </Col>
                ))}
            </Row>
            </Element>
        </Container>
        
    );
};

export default Portfolio;
