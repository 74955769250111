import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap';
import { FaLinkedinIn, FaInstagram, FaDribbble, FaDatabase, FaCode, FaPalette, FaPencilAlt, FaSearch, FaBehance, FaDownload, FaSitemap, FaUserCheck, FaMagic, FaClock, FaEnvelope} from 'react-icons/fa';
import InstagramPosts from '../components/InstagramPosts';
import Loader from '../components/Loader';
import image from '../assets/new-profile-crop.jpg';
import '../css/About.css';
import '../css/common.css';

const Skills = ({ icon, skill }) => {
    return (
        <Card className="text-center border-0 mx-4" style={{backgroundColor:"#fbfcf8"}}>
            <Card.Body style={{width: '200px'}}>
            <div className="border border-5 rounded-circle p-3 mx-auto mb-3 d-flex justify-content-center align-items-center"
            style={{
                width: '80px',
                height: '80px',
                fontSize: '1.2rem',
            }}
            >
                {icon}
            </div>
                <Card.Title style={{ fontSize: '1rem' }}>{skill}</Card.Title>
            </Card.Body>
        </Card>
    );
};

const About = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false);
        }, 1700);
    
        return () => clearTimeout(timer);
      }, []);
    
  return (
    // <div>
    // {loading ? (
    //     <Loader />
    //   ) : (
    <Container className="my-5">
        {/* <h1>About Me</h1> */}
        <Row className=" my-5">
            <Col className="d-flex align-items-stretch" md={5} sm={12}>
                <Image src={image} alt="Voleta-Profile-Pic" className="img-fluid w-100 h-100 object-fit-cover rounded rounded-5" />
            </Col>
            <Col md={1} sm={12} className='mb-4'></Col>
            <Col md={6} sm={12} className='mb-4'>
                <h1 className=" mb-4"style={{fontWeight: "600", fontSize: "36px"}}>
                    Hi there! So glad you made it here :)
                </h1>

                <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                I'd assume you'd like to hear a little bit more about me. So here we go.. I kickstarted my career at a web hosting company, where I managed both the technical and creative aspects of websites and helped customers build their online presence. It was there I discovered my love for user-centered design and creating smooth, effective user experiences.
                </p>

                <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                My curiosity then led me to Humber, where I dove deeper into research, wireframing, prototyping, and interaction design. Now, I combine my tech and creative skills to develop innovative solutions that truly make a difference.
                </p>
                <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                Beyond design, I love hiking and am an avid reader, currently immersed in Lucy Foley's thriller, The Guest List. It’s a must-read for mystery enthusiasts!
                </p>
                <Button href="https://drive.google.com/file/d/1edD8aTjx4I1ahEeXtG3jBcDtEvojeILd/view?usp=sharing" target="_blank" rel="noopener noreferrer" className='button-primary' variant="primary">
                    {/* <FaDownload style={{ marginRight: '8px' }} /> Download my Resume */}
                    View Resume
                </Button>
                <div className="social-links" style={{ fontSize: "24px", marginTop: "5px"}}>
                    <a href="mailto:work.voleta@gmail.com" target="_blank" rel="noopener noreferrer"><FaEnvelope style={{ marginRight: "15px" }} /></a>
                    <a href="https://www.linkedin.com/in/voleta-noronha/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn style={{ marginRight: "15px" }} /></a>
                    <a href="https://www.behance.net/voletanoronha/" target="_blank" rel="noopener noreferrer"><FaBehance style={{ marginRight: "15px" }} /></a>
                </div>
            </Col>
        </Row>
        
        <Row className="justify-content-around my-5 flex-column">
            <Col className='my-4'>
            <h2 className="fw-700 display-8 my-4"style={{ fontWeight: "700"}}>the skills i’m proud to call my UX Toolkit</h2>
            </Col>
            <Col>
            <div className="d-flex flex-wrap align-items-top justify-content-center mt-3">
                <Skills icon={<FaSearch />} skill="User Research" />
                <Skills icon={<FaMagic />} skill="Interaction Design" />
                <Skills icon={<FaPencilAlt />} skill="Wireframing" /> 
                <Skills icon={<FaUserCheck />} skill="User Testing" />      
            </div>
            </Col>
            <Col>
            <div className="d-flex flex-wrap align-items-top justify-content-center mb-3">
                <Skills icon={<FaClock />} skill="Rapid Prototyping" />
                <Skills icon={<FaSitemap />} skill="Information Architecture" />
                <Skills icon={<FaCode />} skill="Web Development" />
                <Skills icon={<FaDatabase />} skill="MERN Stack" />
                
            </div>
            </Col>
            
            
        </Row>
        
        {/* <Row className="justify-content-around my-5 flex-column"> 
         <h2 className="fw-700 display-8 my-3 "style={{ fontWeight: "700"}
        }>my playground</h2> 
        <div className="d-flex flex-wrap align-items-top justify-content-center mt-3">
        <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                I have an <a href="https://www.instagram.com/voleta.noronha/?hl=en">Instagram Account</a> where i post my DailyUI Challenges, hikes and the books i'm currently reading. Here are a few of them
                </p>
            </div>
            
         </Row> */}
    </Container>
    //   )}
    //   </div>
  );
};

export default About;