import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaBehance, FaLinkedinIn, FaDribbble, FaInstagram } from 'react-icons/fa';
import '../css/Contact.css';
import '../css/common.css';
import gif from '../assets/email_sent-removebg-preview.png';

const SocialLink = ({ link, skill, icon }) => {
  return(
    // <Row className="align-items-center no-gutters mx-3">
      <Col xs={6} sm={4} md={2} >
        <a href={link} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
          <div
            className="social-link-circle"
            style={{
              fontSize: '1.2rem',
            }}
          >
            {icon}
          </div>
        </a>
      </Col>
    // </Row>
  );  
};

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [sent, setSent] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://voletanoronha.com:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formState)
      });
      
      if (response.ok) {
        console.log('Email sent successfully');
        setFormState({ name: '', email: '', message: '' });
        setSent(true);
      } else {
        console.log('Error sending email');
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <Container className='my-5'>
        <div className="text-center">
          <h1 className="display-3">Let's Collaborate and Innovate</h1>
          <Image src={gif} alt="email gif" 
            style={{ width: '50%' }} 
          />
          <p style={{fontSize:"24px", fontWeight:"700"}}><a href='mailto:voleta.noronha@gmail.com' className="text-decoration-none" style={{color:"#313638"}}> Email me at voleta.noronha@gmail.com</a></p>
          <p style={{ fontWeight: "500"}}>I'm excited to contribute and bring our shared vision to life. Let's join forces on the next project, turning imaginative ideas into impactful solutions. </p>
        </div>
        
      <Row className='my-5 justify-content-center'>
        <SocialLink link="mailto:voleta.noronha@gmail.com" skill="Gmail" icon={<FaEnvelope size={50} className='icon'/>} />

        <SocialLink link="https://www.behance.net/voletanoronha/" skill="Behance" icon={<FaBehance size={50} className='icon'/>} />

        <SocialLink link="https://www.linkedin.com/in/voleta-noronha/" skill="Linkedin" icon={<FaLinkedinIn size={50} className='icon'/>} />

        <SocialLink link="https://dribbble.com/Voleta" skill="Dribble" icon={<FaDribbble size={50} className='icon'/>} />

        <SocialLink link="https://www.instagram.com/voleta.noronha/" skill="Instagram" icon={<FaInstagram size={50} className='icon'/>} />
      </Row>
    </Container>
  );
};

export default Contact;
