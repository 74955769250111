import React, { useState, useEffect } from 'react';
import { IoIosArrowUp } from "react-icons/io";
import '../css/ScrollToTopArrow.css';

const ScrollToTopArrow = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollHeight = window.scrollY;

    if (scrollHeight > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="scroll-to-top" className={isVisible ? 'visible' : 'hidden'} onClick={scrollToTop}>
      <IoIosArrowUp style={{ fontSize: "25px"}}/>
    </div>
  );
};

export default ScrollToTopArrow;