import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaLinkedinIn, FaInstagram, FaDribbble, FaEnvelope, FaPhone, FaBehance } from 'react-icons/fa';
import image from '../assets/voleta-logo-removebg-preview.png';
import '../css/common.css'

const Footer = () => {
  return (
    <footer className="p-3 footer" style={{ width: "80%", margin: "0 auto", textAlign: "center" }}>
<Container className='text-center'>
    {/* First Row */}
    {/* <Row className='mb-2'>
        <Col>
            <p className="mb-0" style={{ fontSize: "16px", fontStyle: "italic" }}>
                "Creating thoughtful &amp; accessible user experiences"
            </p>
        </Col>
    </Row> */}

    {/* Second Row */}
    <Row className="mb-3">
        <Col md={12} className='social-links d-flex justify-content-center align-items-center' style={{ fontSize: "20px"}}>
          <a href="https://www.instagram.com/voleta.noronha/" target="_blank" rel="noopener noreferrer"><FaInstagram style={{ marginRight: "10px" }} /></a>
          <a href="https://www.behance.net/voletanoronha/" target="_blank" rel="noopener noreferrer"><FaBehance style={{ marginRight: "10px" }} /></a>
          <a href="mailto:work.voleta@gmail.com" target="_blank" rel="noopener noreferrer"><FaEnvelope style={{ marginRight: "10px" }} /></a>
          <a href="https://www.linkedin.com/in/voleta-noronha/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn style={{ marginRight: "10px" }} /></a>
          <a href="https://dribbble.com/Voleta" target="_blank" rel="noopener noreferrer"><FaDribbble style={{ marginRight: "10px" }} /></a>
        </Col>
    </Row>

    {/* Third Row */}
    <Row className='mb-3'>
        <Col md={12}>
            <p style={{color:"#a4a4a4"}}>&copy; Voleta Noronha {new Date().getFullYear()}</p>
        </Col>
    </Row>
</Container>

    </footer>

  );
}

export default Footer;