import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import '../css/ProjectCard.css'; // Assuming you have a separate CSS file for styling
import '../css/common.css';
import { Link } from 'react-router-dom';

const ProjectCard = (props)=>{
    const { name, description, prototypeLink, caseStudyLink, projectType, imageSrc, reverse } = props;
    return (
        <div className={`project-card-container mb-4 mt-4`} style={{minHeight:"60vh"}}>
            <Row className={`align-items-top justify-content-center mx-0 ${reverse ? 'flex-row-reverse' : ''}`}>
                {/* Left Side */}
                <Col xs={12} md={6} lg={3} className="text-center text-md-left" >
                    <h2 className="mb-4" style={{fontWeight:"700", fontSize:"32px", lineHeight:"1.6em"}}>{name}</h2>
                    <p className="mb-4" style={{lineHeight:"2em", fontWeight:"500"}}>{description}</p>
                    <div className="project-types mb-4 d-flex flex-wrap">
                        {projectType.map((type, index) => (
                            <div key={index} className="project-type-rectangle mb-3" style={{ fontWeight: "500" }}>
                                {type}
                            </div>
                        ))}
                    </div>
                    {caseStudyLink && (
                        <Link to={caseStudyLink} target="_blank" rel="noopener noreferrer">
                            <Button className='button-projects mb-4 mr-4'>View Case Study</Button>
                        </Link>
                    )}
                    {prototypeLink && (
                        <Link to={prototypeLink} target="_blank" rel="noopener noreferrer">
                            <Button className='button-projects mb-4'>View Prototype</Button>
                        </Link>
                    )}
                </Col>
                {/* Right Side */}
                <Col xs={12} md={5} lg={6} className={`project-image-container ${reverse ? 'text-left' : 'text-right'} `}>
                    {/* Image of the Project */}
                    <Link to={caseStudyLink}>
                        <img src={imageSrc} alt="Project Image" className="img-fluid project-image" style={{width:"100%"}}/>
                    </Link>
                    
                </Col>
            </Row>
        </div>
    );
};

export default ProjectCard;
